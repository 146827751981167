/* eslint-disable import/prefer-default-export */
export const ufOptions = [
  { title: "AC", value: "AC" },
  { title: "AL", value: "AL" },
  { title: "AP", value: "AP" },
  { title: "AM", value: "AM" },
  { title: "BA", value: "BA" },
  { title: "CE", value: "CE" },
  { title: "DF", value: "DF" },
  { title: "ES", value: "ES" },
  { title: "GO", value: "GO" },
  { title: "MA", value: "MA" },
  { title: "MT", value: "MT" },
  { title: "MS", value: "MS" },
  { title: "MG", value: "MG" },
  { title: "PA", value: "PA" },
  { title: "PB", value: "PB" },
  { title: "PR", value: "PR" },
  { title: "PE", value: "PE" },
  { title: "PI", value: "PI" },
  { title: "RJ", value: "RJ" },
  { title: "RN", value: "RN" },
  { title: "RS", value: "RS" },
  { title: "RO", value: "RO" },
  { title: "RR", value: "RR" },
  { title: "SC", value: "SC" },
  { title: "SP", value: "SP" },
  { title: "SE", value: "SE" },
  { title: "TO", value: "TO" },
  { title: "EX", value: "EX" },
]

export const status = [
  { title: 'Todos', value: '' },
  { title: 'Anexar Documentos', value: 'anexar documentos' },
  { title: 'Aprovado', value: 'aprovado' },
  { title: 'Cancelada', value: 'cancelada' },
  { title: 'Documentação insuficiente', value: 'documentacao insuficiente' },
  { title: 'Em Análise', value: 'em análise' },
  { title: 'Proposta Inconsistente', value: 'proposta inconsistente' },
  { title: 'Venda efetivada', value: 'venda efetivada' },
  { title: 'Vencida', value: 'vencida' },
  { title: 'Cancelada por solicitação', value: 'cancelada por solicitação' },
  { title: 'Cancelada por inadimplência', value: 'cancelada por inadimplência' },
  {title: 'Sinal pendente', value: 'sinal pendente'},
]

export const comJuros = [
  { title: 'Sim', value: true },
  { title: 'Não', value: false }
]
