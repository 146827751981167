import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { Content, TabelaContainer, OpcoesContainer, ContainerInputs, ContainerButton } from './styles';
import Botao from '../../components/Botao/Botao';
import UsuariosService from '../../services/UserService';
import DeleteModal from '../../components/DeleteModal';
import Snackbars from '../../components/Snackbars';
import colors from '../../styles/cores';
import PaginatedTable from '../../components/PaginatedTable/PaginatedTable';
import InputField from '../../components/Inputs/InputField';
import ImobiliariasService from '../../services/ImobiliariasService';

interface ListAllCorretores {
  name?: string
  agency?: string
  user_type?: string
  email?: string
  document?: string
  count?: number
  page?: number
  limit?: number
}

interface Agency {
  id: number;
  nome: string;
}

const Index: React.FC = () => {
  const [userData, setUserData] = useState({});
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [listarCorretores, setListarCorretores] = useState<any>();
  const [totalCount, setTotalCount] = useState(1);
  const [filter, setFilter] = useState<ListAllCorretores>({
    page: 1,
    count: 10,
    limit: 10
  } as ListAllCorretores);
  const [totalPages, setTotalPages] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [searchTimeout, setSearchTimeout] = useState<any>(null);
  const [allAgencyData, setAllAgencyData] = useState<Agency[]>([]);
  const [selectedAgency, setSelectedAgency] = useState<Agency | null>(null);

  const history = useHistory();

  const [snackbarProps, setSnackbarProps] = useState({
    message: '',
    type: 'success',
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  function handleFilterChange(key: string, value: string | Agency | null, page = 1) {
    setFilter(currentState => {
      const newState = {
        ...currentState,
        page: 1,
        [key]: value,
      };
      return newState;
    });
  }

  useEffect(() => {
    if (totalPages !== undefined) {
      setTotalCount((filter.count || 0) * totalPages);
    }
  }, [filter.count, totalPages]);


  const fetchData = useCallback(async params => {
    setIsDataLoading(true);
    await UsuariosService.listAllCorretores(params)
      .then(response => {
        const totalPages = response.totalPagesNumber;
        setTotalPages(totalPages)
        const corretores = response.users.map(
          (corretor: any) => {
            return {
              name: corretor.name,
              agency: corretor.agency,
              user_type: corretor.user_type,
              email: corretor.email,
              document: corretor.document,
              is_inactive: corretor.is_inactive,
              new: corretor.new,
              id: corretor.id,
              avatar: corretor.avatar
            }
          }
        );
        setListarCorretores([...corretores]);

      })
      .catch(err => console.log('erro -> ', err));
    setIsDataLoading(false);
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetchData(filter);
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [filter]);

  const handlePageChange = (newPage: number) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      page: newPage,
    }));
  };

  const columns = [
    {
      title: 'Avatar',
      field: 'imageUrl',
      filtering: false,
      export: false,
      render: (rowData: any) => (
        <img
          src={
            rowData.avatar_url ||
            'https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Missing_avatar.svg/240px-Missing_avatar.svg.png'
          }
          style={{ width: 64, height: 64, borderRadius: '50%' }}
          alt="avatar"
        />
      ),
    },
    {
      title: '',
      field: 'new',
      render: (rowData: any) => (
        rowData.new ? (
          <span
            style={{
              backgroundColor: colors.purple_3,
              color: 'white',
              padding: '10px 15px',
              borderRadius: '4px',
            }}
          >
            Novo
          </span>
        ) : null
      ),
    },
    {
      title: 'Nome',
      field: 'name',
      export: false,
    },
    {
      title: 'Email',
      field: 'email',
      export: true,
    },
    {
      title: 'Tipo',
      field: 'user_type',
    },
    {
      title: 'Documento',
      field: 'document',
    },
    {
      title: 'Imobiliária',
      field: 'agency.nome',
    },
    {
      title: 'Ativo',
      field: 'is_inactive',
      render: (rowData: any) => {
        const isActive = rowData.is_inactive === null || !rowData.is_inactive;
        return (
          <span
            style={{
              backgroundColor: isActive ? colors.lime : colors.red,
              color: 'white',
              padding: '10px 15px',
              borderRadius: '4px',
            }}
          >
            {isActive ? 'Ativo' : 'Inativo'}
          </span>
        );
      },
    },
  ];

  function rowSelected(e: any, rowData: any) {
    const id = rowData.id;
    history.push({ pathname: '/painel/corretores/detalhes/', state: { id } });
  }

  function adicionarCorretor() {
    history.push(`/painel/corretores/adicionar`);
  }

  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  async function editarCorretores(rowData: any) {
    const userData = await UsuariosService.getUserData(rowData.id);
    const userDataPersonal = userData.user;
    const userDataAddress = userData.user.address || {};

    history.push({
      pathname: '/painel/corretores/editar',
      state: {
        id: userDataPersonal.id,
        name: userDataPersonal.name,
        document: userDataPersonal.document,
        email: userDataPersonal.email,
        birth_date: userDataPersonal.birth_date,
        phone: userDataPersonal.phone,
        agency_name: userDataPersonal.agency.nome,
        agency_id: userDataPersonal.agency_id,
        avatar_url: userDataPersonal.avatar_url,
        cep: userDataAddress.cep,
        rua: userDataAddress.rua || '',
        numero: userDataAddress.numero || '',
        bairro: userDataAddress.bairro || '',
        cidade: userDataAddress.cidade || '',
        uf: {
          title: userDataAddress.uf || '',
          value: userDataAddress.uf || ''
        },
        complemento: userDataAddress.complemento || '',
        enterprises: userDataPersonal.enterprises || [],
        creci: userDataPersonal.creci || '',
      },
    });
  }

  async function deletarRegistro(deleteData: any) {
    await UsuariosService.deleteUser(deleteData.id);
    setDeleteModalOpen(false);
    setSnackbarProps({
      message: 'Corretor deletado com sucesso',
      type: 'success',
    });
    setSnackbarOpen(true);
    fetchData(filter);
  }

  const fetchDataImob = useCallback(async () => {
    setIsDataLoading(true);
    await ImobiliariasService.listAllImobiliarias().then((response) => {
      setAllAgencyData(response.agencies)
    })
    setIsDataLoading(false);
  }, [])

  useEffect(() => {
    fetchDataImob();
  }, [fetchDataImob]);

  return (
    <>
      <Content>
        <OpcoesContainer>
          <ContainerButton>
            <Botao tipo="adicionar" tamanho="medio" onClick={adicionarCorretor}>
              Adicionar Corretor
            </Botao>
          </ContainerButton>

          <ContainerInputs>
            <InputField
              tipo="formulario"
              className="inputField"
              label="Nome"
              onChange={(e: any) => {
                handleFilterChange('name', e.target.value, 1);
              }}
              value={filter.name}
              width={160}
            />

            <InputField
              tipo="formulario"
              className="inputField"
              label="Email"
              onChange={(e: any) => {
                handleFilterChange('email', e.target.value, 1);
              }}
              value={filter.email}
              width={160}
            />

            <InputField
              tipo="formulario"
              className="inputField"
              label="Documento"
              onChange={(e: any) => {
                handleFilterChange('document', e.target.value, 1);
              }}
              value={filter.document}
              width={160}
            />

            <Autocomplete
              id="agency-filter"
              options={allAgencyData}
              getOptionLabel={(option) => option.nome}
              style={{ width: 160 }}
              size='small'
              renderInput={(params) =>
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  label="Imobiliária"
                  variant="outlined"
                  style={{ width: 250}}
                />
              }
              value={selectedAgency}
              onChange={(event, newValue) => {
                setSelectedAgency(newValue);
                handleFilterChange('agency', newValue ? newValue.nome : null);
              }}
            />
          </ContainerInputs>
        </OpcoesContainer>

        <TabelaContainer>
          <PaginatedTable
            title="Corretores"
            haveActions="none"
            isLoading={isDataLoading}
            columns={columns}
            data={listarCorretores}
            onRowClick={(e: any, rowData: any): any => {
              setListarCorretores(rowData);
              rowSelected(e, rowData);
            }}
            excluirClick={() => {
              console.log('legal');
            }}
            editarClick={(e: any, rowData: any): any => {
              setListarCorretores(rowData);
            }}
            count={totalCount}
            totalCount={totalCount}
            currentPage={filter.page}
            setCurrentPage={handlePageChange}
          />
        </TabelaContainer>
        <DeleteModal
          isModalOpen={deleteModalOpen}
          onCloseModal={() => setDeleteModalOpen(false)}
          onDeleteClick={() => deletarRegistro(userData)}
        />
        <Snackbars
          type={snackbarProps.type}
          handleClose={handleSnackbarClose}
          open={snackbarOpen}
        >
          {snackbarProps.message}
        </Snackbars>
      </Content>
    </>
  );
};

export default Index;
