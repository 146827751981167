/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import UpdateIcon from '@material-ui/icons/Update';
import { useSelector } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { RootState } from '../../storage/rootReducer';

import { Content, TabelaContainer, Status, OpcoesContainer } from './styles';
import Tabela from '../../components/Tabelas/Tabela';
import HeaderProposta from '../../components/HeaderProposta';
import cores from '../../styles/cores';
import PropostasService from '../../services/PropostasService';
import { isSignedIn } from '../../services/auth';
import PaginatedTable from '../../components/PaginatedTable/PaginatedTable';
import InputField from '../../components/Inputs/InputField';
import { status } from '../../utils/selects';

interface IFilterInterface {
  page: number;
  count: number;
  status: string;
  enterprise: string;
}

const Propostas: React.FC = () => {
  const [detalhes, setDetalhes] = useState(false);
  const [userData, setUserData] = useState({});
  const [pageMenu, setPageMenu] = useState('principal');
  const [listarPropostas, setListarPropostas] = useState<any[]>();
  const [listPhysical, setListPhysical] = useState([]);
  const [listLegal, setListLegal] = useState([]);
  const history = useHistory();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [id, setId] = useState('');
  const { state } = useLocation();
  const { token } = useSelector((state: RootState) => state.userReducer);

  const [searchTimeout, setSearchTimeout] = useState<any>(null);

  const [count, setCount] = useState<number>(30);
  const [totalCount, setTotalCount] = useState(1);
  const [filter, setFilter] = useState<IFilterInterface>({
      page: 1,
      count: 10,
      status: '',
      enterprise: '',
    } as IFilterInterface);
  const [selectedStatus, setSelectedStatus] = useState({ title: '', value: '' })

  async function statusSelect(e: any, value: any) {
      setSelectedStatus({
        title: value.title,
        value: value.value,
      });
      setFilter(currentState => ({
        ...currentState,
        status: value.value,
      }));
    }

  const list = useCallback(async (params) => {
    setIsDataLoading(true);
    const responseData = await PropostasService.getProposalsPhysical(params);
    setCount(responseData.totalPagesNumber)
    setTotalCount(responseData.count)
    const physical = responseData.proposalsPhysical
    let physicalFormatted = physical?.map((item: any) => {
      let documento;
      let nome;
      let physical_formatted;

      if (item.proposals_participants.length > 0) {
        const findPrincipal = item.proposals_participants_type_percentage.find(
          (proposals_participant_type_percentage: {
            tipo_participante: string;
          }) =>
            proposals_participant_type_percentage.tipo_participante ===
            'principal',
        );
        const principal = item.proposals_participants.find(
          (proposals_participant: { id: any }) =>
            proposals_participant.id === findPrincipal?.id_participante,
        );
        documento = principal?.cpf;
        nome = principal?.nome;
      }

      physical_formatted = {
        id: item.id,
        nome: nome,
        documento: documento,
        status: item.status,
        atualizacao: item.updated_at,
        estado_civil: item.estado_civil,
        sexo: item.sexo,
        data_nascimento: item.data_nascimento,
        num_filhos: item.num_filhos,
        renda_familiar: item.renda_familiar,
        num_entradas: item.num_entradas,
        valor_entrada: item.valor_entrada,
        num_parcelas: item.num_parcelas,
        valor_parcelas: item.valor_parcelas,
        valor_total: item.valor_total,
        objetivo: item.objetivo,
        telefone: item.telefone,
        email: item.email,
        criacao: item.created_at,
        documentosFisica: item.documentos,
        participantes: item.proposals_participants,
        empreendimento: item.empreendimento,
        clt: item.clt,
        cod_mega_proposta_ativa: item.cod_mega_proposta_ativa,
        quadra_lote:
          item.quadra_lote === '0/0' ? 'Não se aplica' : item.quadra_lote,
        tipo: 'physical',
      };

      if (
        item.data_vencimento_parcela === null ||
        item.data_vencimento_parcela === undefined ||
        item.data_vencimento_sinal === null ||
        item.escolaridade === null ||
        item.escolaridade === '' ||
        item.clt === '' ||
        item.clt === undefined ||
        item.clt === null ||
        item.clt === 'null' ||
        item.clt === 'undefined' ||
        item.clt === 'null' ||
        item.clt === 'undefined'
      ) {
        physical_formatted = {};
      }

      return physical_formatted;
    });
    setListarPropostas(physicalFormatted || []);
    setIsDataLoading(false);
  }, []);

  useEffect(() => {
    if (!isSignedIn()) {
      history.push('/');
    }
    // list();
  }, []);

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout)
    }

    const timeoutId = setTimeout(() => {
      list(filter)
    }, 500)

    setSearchTimeout(timeoutId)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [filter])

  const columns = [
    {
      title: 'Última atualização',
      field: 'atualizacao',
      type: 'date',
    },
    {
      title: 'Participante Principal',
      field: 'nome',
    },
    {
      title: 'Documento (CPF/CNPJ)',
      field: 'documento',
    },
    {
      title: 'Empreedimento',
      field: 'empreendimento',
    },
    {
      title: 'Quadra / Lote',
      field: 'quadra_lote',
    },
    {
      title: 'Código',
      field: 'cod_mega_proposta_ativa',
    },
    {
      title: 'Status',
      field: 'status',
      render: (rowData: any) => {
        if (rowData.status === 'Em Análise') {
          return (
            <Status>
              <UpdateIcon
                style={{ fill: 'orange', marginRight: 5, fontSize: 26 }}
              />
              Em análise
            </Status>
          );
        }
        if (rowData.status === 'Aprovado') {
          return (
            <Status>
              <DoneIcon
                style={{ fill: 'green', marginRight: 5, fontSize: 26 }}
              />
              Aprovado
            </Status>
          );
        }
        if (rowData.status === 'Anexar Documentos') {
          return (
            <Status>
              <AttachFileIcon
                style={{ fill: cores.blue, marginRight: 5, fontSize: 26 }}
              />
              Anexar documentos
            </Status>
          );
        }
        if (rowData.status === 'Reprovado') {
          return (
            <Status>
              <ClearIcon
                style={{ fill: 'red', marginRight: 5, fontSize: 26 }}
              />
              Reprovado
            </Status>
          );
        }
        return <Status>{rowData.status || 'Status não definido'}</Status>;
      },
    },
  ];

  const [isModalOpen, setModalOpen] = useState(false);

  function rowSelected(e: any, rowData: any) {
    history.push({
      pathname: '/painel/proposta/editar',
      state: { rowData },
    });
  }
  function adicionarCorretor() {
    setPageMenu('adicionar');
  }

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setModalOpen(false);
    setOpen(true);
  };

  function handleFilterChange(key: string, value: string) {
      setFilter(currentState => ({
        ...currentState,
        [key]: value,
      }));
  }

  return (
    <>
      <HeaderProposta />
      <Content>
        <OpcoesContainer>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: '4%',
                      marginBottom: '2%',
                      flexDirection: 'row',
                      gap: '15px',
                    }}
                  >

                    <InputField
                      tipo="formulario"
                      className="inputField"
                      label="Empreendimento"
                      onChange={(e: any) => {
                        handleFilterChange('enterprise', e.target.value);
                      }}
                      value={filter.enterprise}
                      width={160}
                    />

                    <Autocomplete
                      id="combo-box-demo"
                      options={status}
                      getOptionLabel={(option: any) => option.title}
                      style={{ width: 200 }}
                      value={selectedStatus}
                      onChange={(e: any, value: any) => statusSelect(e, value)}
                      disableClearable
                      renderInput={params => (
                        <TextField
                          style={{ width: 200 }}
                          label="Status"
                          variant="outlined"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          size="small"
                          error={false}
                          helperText=""
                        />
                      )}
                    />
                  </div>
                </OpcoesContainer>
        <TabelaContainer>
          <PaginatedTable
              title="Propostas"
              haveActions="none"
              isLoading={isDataLoading}
              columns={columns}
              data={listarPropostas}
              onRowClick={(e: any, rowData: any): any => {
                setListarPropostas(rowData);
                rowSelected(e, rowData);
              }}
              excluirClick={() => {
                console.log('');
              }}
              editarClick={(e: any, rowData: any): any => {
                setListarPropostas(rowData);
                rowSelected(e, rowData);
              }}
              count={count}
              totalCount={totalCount}
              currentPage={filter.page}
              setCurrentPage={(page: string) => handleFilterChange('page', page)}
            />
        </TabelaContainer>
      </Content>
    </>
  );
};

export default Propostas;
