import React, { useEffect, useState } from 'react';
import { TabelaContainer } from '../AcompanhamentoPropostaAdmin/styles';
import Tabela from '../../components/Tabelas/Tabela';
import PropostasService from '../../services/PropostasService';

interface EntryReport {
  id_parcela_cp: number;
  sequencia: string;
  tipo: string;
  valor: string;
  valor_pago: string;
  condicao_vendas: string;
  status: string;
  adiantamento_mega: string;
  dias_vencimento: string;
  id_contrato_vendas: number;
  id_lote: number;
  id_proposta_vimob: number;
  data_atualizacao: string;
  data_compra: string;
  data_criacao_parcela: string;
  data_pagamento: string | null;
  data_vencimento: string;
  cliente: string;
  empreendimento: string;
  lote: string;
  quadra: string;
}

const DadosSinais = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<EntryReport[]>([]);

  const orderReports = (reports: EntryReport[]): EntryReport[] => {
    return reports.sort((a, b) => {
      if (a.status === 'Pendente' && b.status !== 'Pendente') return -1;
      if (a.status !== 'Pendente' && b.status === 'Pendente') return 1;

      const parseDate = (date: string | undefined): Date => {
        if (!date) return new Date(0);
        const [day, month, year] = date.split('/');
        return new Date(`${year}-${month}-${day}`);
      };

      const dateA = parseDate(a.data_vencimento);
      const dateB = parseDate(b.data_vencimento);

      if (Number.isNaN(dateA.getTime())) return 1;
      if (Number.isNaN(dateB.getTime())) return -1;

      const today = new Date();
      const diffA = Math.abs(today.getTime() - dateA.getTime());
      const diffB = Math.abs(today.getTime() - dateB.getTime());

      return diffA - diffB;
    });
  };

  function formatCurrency(value: string | number) {
    let number = value;

    if (typeof number === 'string') {
      number = Number(number);
    }
    if (Number.isInteger(number)) {
      return number.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    } else {
      number = Number(number.toFixed(2));
      return number.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    }
  }

  const formatDate = async (reports: EntryReport[]) => {
    const formattedReports = reports.map(report => ({
      ...report,
      data_atualizacao: new Date(report.data_atualizacao).toLocaleDateString(),
      data_compra: new Date(report.data_compra).toLocaleDateString(),
      data_criacao_parcela: new Date(
        report.data_criacao_parcela,
      ).toLocaleDateString(),
      data_pagamento: report.data_pagamento
        ? new Date(report.data_pagamento).toLocaleDateString()
        : null,
      data_vencimento: new Date(report.data_vencimento).toLocaleDateString(),
      valor: formatCurrency(report.valor),
      valor_pago: formatCurrency(report.valor_pago),
      nome_participante: report.cliente,
      emp_quadra_lote: `${report.empreendimento}: ${report.quadra} - ${report.lote}`
    }));
    return formattedReports;
  };

  const getEntryReportsData = async () => {
    setIsLoading(true);
    const entryReports = await PropostasService.getEntryReports();
    const formattedData = await formatDate(entryReports);

    if (formattedData) {
      const orderedReports = await orderReports(formattedData);
      setTableData(orderedReports);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getEntryReportsData();
  }, []);

  const columns = [
    {
      title: 'Data Vencimento',
      field: 'data_vencimento',
      type: 'date',
      render: (rowData: any) => {
        const dataVencimento = rowData.data_vencimento;
        const pagamento = rowData.status;

        const [day, month, year] = dataVencimento.split('/');

        const vencimentoDate = new Date(
          Date.UTC(Number(year), Number(month) - 1, Number(day), 0, 0, 0),
        );

        const today = new Date();
        today.setUTCHours(0, 0, 0, 0);

        const diffTime = vencimentoDate.getTime() - today.getTime();
        const diffDays = diffTime / (1000 * 3600 * 24);

        let style = {};
        let label = '';

        if (pagamento === 'Pago') {
          style = { color: 'green' };
          label = 'Pago';
        } else if (diffDays === 0) {
          style = { color: '#E49B0F' };
          label = 'Hoje';
        } else if (diffDays < 0) {
          style = { color: 'red', fontWeight: 'bold' };
          label = 'Atrasado';
        } else if (diffDays > 0 && diffDays <= 5) {
          style = { color: '#E49B0F' };
          label = 'Eminente';
        }

        return (
          <div style={style}>
            <strong>
              {label} - {dataVencimento}
            </strong>
          </div>
        );
      },
    },
    {
      title: 'Código mega',
      field: 'id_proposta_vimob',
    },
    {
      title: 'Nome',
      field: 'nome_participante',
    },
    {
      title: 'Empreendimento',
      field: 'emp_quadra_lote',
    },
    {
      title: 'Valor',
      field: 'valor',
    },
    {
      title: 'Valor Pago',
      field: 'valor_pago',
    },
    {
      title: 'Sequência',
      field: 'sequencia',
    },
    {
      title: 'Status',
      field: 'status',
    },
    {
      title: 'Tipo',
      field: 'tipo',
    },
  ];

  return (
    <TabelaContainer>
      <Tabela
        title="Relatórios"
        columns={columns}
        isLoading={isLoading}
        data={tableData}
        onRowClick={(e: any, rowData: any): any => {
          console.log('');
        }}
        excluirClick={() => {
          console.log('');
        }}
        editarClick={(e: any, rowData: any): any => {
          console.log('');
        }}
        pagination
        paginationPageSize={10}
        haveActions='none'
      />
    </TabelaContainer>
  );
};

export default DadosSinais;
